<template>
    <div class="wrap">
        <div class="flex justify-start" style="padding: 20px">
            <el-form :inline="true" :model="searchForm">
                <el-form-item label="活动名称">
                    <el-input v-model="searchForm.activity_name"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="search()">查询</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button plain @click="reset()">重置</el-button>
                </el-form-item>
                <!-- <el-form-item>
                    <el-button type="primary" @click="showAction()">购卡</el-button>
                </el-form-item> -->
            </el-form>
        </div>
        <el-table :data="tableData" v-loading="loading">
            <el-table-column type="index" width="50"></el-table-column>
            <el-table-column prop="activity_name" label="活动名称" />
            <el-table-column label="活动封面">
                <template slot-scope="scope">
                    <el-image style="width: 50px; height: 50px" :src="scope.row.activity_image" :preview-src-list="[scope.row.activity_image]" />
                </template>
            </el-table-column>
            <el-table-column prop="activity_price" label="价格" align="center">
                <template slot-scope="scope">
                    {{ `￥${scope.row.activity_price}` }}
                </template>
            </el-table-column>
            <el-table-column prop="total_num" label="购卡总数" align="center" />
            <el-table-column prop="last_num" label="剩余卡量" align="center" />
            <el-table-column prop="total_money" label="累计金额" align="center">
                <template slot-scope="scope">
                    {{ `￥${scope.row.total_money}` }}
                </template>
            </el-table-column>
            <el-table-column prop="withdraw_money" label="已提现" align="center">
                <template slot-scope="scope">
                    {{ `￥${scope.row.withdraw_money}` }}
                </template>
            </el-table-column>
            <el-table-column prop="withdraw_money_apply" label="提现中" align="center">
                <template slot-scope="scope">
                    {{ `￥${scope.row.withdraw_money_apply}` }}
                </template>
            </el-table-column>
            <el-table-column prop="balance" label="余额" align="center">
                <template slot-scope="scope">
                    {{ `￥${scope.row.balance}` }}
                </template>
            </el-table-column>
            <el-table-column prop="status" label="活动状态" align="center">
                <template slot-scope="scope">
                    <el-button type="info" size="mini" plain v-if="scope.row.status === -1">已解除</el-button>
                    <el-button type="primary" size="mini" plain v-else-if="scope.row.status === 1">已绑定</el-button>
                </template>
            </el-table-column>
            <el-table-column prop="operate" label="操作" align="center" width="300px">
                <template slot-scope="scope">
                    <el-button type="primary" size="mini" @click="showAction(scope.row.id)">购卡</el-button>
                    <el-button type="primary" size="mini" @click="showWithdraw(scope.row)">提现</el-button>
                    <el-button type="primary" size="mini" @click="toRecord(scope.row.activity_id)">查看购卡记录</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="text-center margin-top">
            <el-pagination
                @current-change="pageChange"
                :current-page="searchForm.page"
                :page-sizes="pageSizes"
                :page-size="searchForm.page_size"
                layout="prev, pager, next, total, jumper"
                :total="searchForm.total"
            ></el-pagination>
        </div>
        <el-dialog title="充值套餐卡" :visible.sync="vipCard.visible" append-to-body :close-on-click-modal="false" width="800px">
            <el-form :model="vipCard.form" label-width="120px" ref="form">
                <el-form-item label="选择套餐卡" prop="activity_id" :rules="rules.required">
                    <el-select v-model="vipCard.form.activity_id" placeholder="请选择" filterable style="width: 300px;">
                        <el-option v-for="(item, index) in vipCard.vipcards" :label="item.name" :value="item.id" :key="index"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="充值数量" prop="num" :rules="rules.required">
                    <el-input-number v-model="vipCard.form.num"></el-input-number>
                </el-form-item>
                <el-form-item label="金额" prop="money" :rules="rules.required">
                    <el-input v-model="vipCard.form.money" disabled style="width: 300px;" />
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="vipCard.visible = false">取 消</el-button>
                <el-button type="primary" :loading="vipCard.loading" @click="submitVipCard()">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog title="提现" :visible.sync="withdraw.visible" append-to-body :close-on-click-modal="false" width="800px">
            <el-form :model="withdraw.form" label-width="120px" ref="form">
                <el-form-item label="当前活动">
                    <el-input v-model="withdraw.current.activity_name" readonly style="width: 300px;" />
                </el-form-item>
                <el-form-item label="最多提现金额">
                    <el-input v-model="withdraw.current.balance" readonly style="width: 300px;" />
                </el-form-item>
                <el-form-item label="提现金额" prop="money" :rules="rules.required">
                    <el-input v-model="withdraw.form.money" style="width: 300px;" />
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="withdraw.visible = false">取 消</el-button>
                <el-button type="primary" :loading="withdraw.loading" @click="submitWithdraw()">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog title="请用微信扫码支付" :visible.sync="payment.visible" append-to-body width="500px" :before-close="handlerClose">
            <div class="flex justify-center">
                <canvas id="qrcode"></canvas>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="payment.loading" @click="payment.visible = false">我已支付</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import rules from '../../../common/common.rules.js';
import QRCode from 'qrcode';
export default {
    data() {
        return {
            searchForm: {
                activity_name: '',
                page: 1,
                page_size: 1,
                total: 0,
            },
            pageSizes: [15],
            loading: false,
            tableData: [],
            vipCard: {
                loading: false,
                visible: false,
                vipcards: [],
                form: {
                    activity_id: '',
                    num: 10,
                    money: 0,
                },
            },
            rules,
            withdraw: {
                loading: false,
                visible: false,
                current: {},
                form: {
                    activity_id: '',
                    money: 0,
                },
            },
            payment: {
                loading: false,
                visible: false,
                qrcode: '',
                id: '',
                timer: null,
            },
        };
    },
    watch: {
        'vipCard.form.activity_id': function() {
            const card = this.vipCard.vipcards.find((s) => s.id === this.vipCard.form.activity_id);
            if (card) {
                this.vipCard.form.money = Number(card.price) * this.vipCard.form.num;
            }
        },
        'vipCard.form.num': function() {
            const card = this.vipCard.vipcards.find((s) => s.id === this.vipCard.form.activity_id);
            if (card) {
                this.vipCard.form.money = Number(card.price) * this.vipCard.form.num;
            }
        },
    },
    created: function() {
        this.search();
        this.$api.platformActivity
            .getActivityList({
                page: 1,
                page_size: 999,
            })
            .then((res) => {
                this.vipCard.vipcards = res.data.data;
            });
    },
    methods: {
        pageChange: function(page) {
            this.searchForm.page = page;
            this.search();
        },
        search: function() {
            this.loading = true;
            let searchForm = this.$util.emptyToUndefined(this.searchForm);
            this.$api.platformActivity.getPCList(searchForm).then((res) => {
                this.searchForm.total = res.data.total;
                this.tableData = res.data.data;
                this.pageSizes = res.data.per_page.toString().split();
                this.searchForm.page_size = res.data.per_page;
                this.loading = false;
            });
        },
        reset: function() {
            this.searchForm.activity_name = '';
            this.search();
        },
        toDetail: function(id) {
            this.$router.push(`/platformActivity/packageCard/detail/${id}`);
        },
        showAction: function(id = '') {
            this.vipCard.form.activity_id = id;
            this.vipCard.visible = true;
        },
        toRecord: function(activity_id) {
            this.$router.push(`/platformActivity/packageCard/record/${activity_id}`);
        },
        showWithdraw: function(obj) {
            this.withdraw.current = obj;
            this.withdraw.visible = true;
            this.withdraw.form.activity_id = obj.activity_id;
        },
        submitWithdraw: function() {
            if (Number(this.withdraw.form.money) > Number(this.withdraw.current.balance)) {
                this.$message({
                    message: '超出最大提现额度！',
                    type: 'error',
                });
                return;
            }
            this.withdraw.loading = true;
            this.$api.platformActivity.withdraw(this.withdraw.form).then((res) => {
                this.withdraw.loading = false;
                if (res.code != 200) {
                    this.$message({
                        message: res.msg,
                        type: 'error',
                    });
                    return;
                }
                this.$message({
                    message: '提现成功',
                    type: 'success',
                });
                this.withdraw.visible = false;
                this.withdraw.form.activity_id = '';
                this.withdraw.form.money = '';
                this.withdraw.current = {};
                this.search(this.searchForm.page);
            });
        },
        submitVipCard: function() {
            this.vipCard.loading = true;
            this.$api.platformActivity.createOrder(this.vipCard.form).then((res) => {
                if (res.code == 200) {
                    this.vipCard.loading = false;
                    this.vipCard.visible = false;
                    this.payment.qrcode = res.data.qrcode;
                    this.payment.id = res.data.id;
                    this.$nextTick(() => {
                        this.createQr();
                    });
                } else {
                    this.vipCard.loading = false;
                    this.$message({
                        message: res.msg,
                        type: 'error',
                    });
                }
            });
        },
        createQr: function() {
            this.payment.visible = true;
            this.$nextTick(() => {
                const qrcode = this.payment.qrcode;
                let opts = {
                    errorCorrectionLevel: 'H', //容错级别
                    type: 'image/png', //生成的二维码类型
                    quality: 0.3, //二维码质量
                    margin: 12, //二维码留白边距
                    width: 300, //宽
                    height: 280, //高
                    text: qrcode, //二维码内容
                    color: {
                        dark: '#333333', //前景色
                        light: '#fff', //背景色
                    },
                };
                this.QRCodeMsg = qrcode; //生成的二维码为URL地址js
                let msg = document.getElementById('qrcode');
                // 将获取到的数据（val）画到msg（canvas）上
                QRCode.toCanvas(msg, this.QRCodeMsg, opts, function(error) {
                    console.log(error);
                });
                this.payment.timer = setInterval(() => {
                    this.$api.platformActivity.checkPay({ id: this.payment.id }).then((res) => {
                        if (res.data === 1) {
                            this.$message({
                                message: '支付成功',
                                type: 'success',
                            });
                            clearInterval(this.payment.timer);
                            this.payment.visible = false;
                            this.search();
                        } else if (res.data === -1) {
                            this.$message({
                                message: '未查询到相关订单',
                                type: 'error',
                            });
                            clearInterval(this.payment.timer);
                            this.payment.visible = false;
                            this.search();
                        }
                    });
                }, 1000);
            });
        },
        handlerClose: function(done) {
            if (this.payment.timer) {
                clearInterval(this.payment.timer);
                this.payment.timer = null;
            }
            done();
            this.search();
        },
    },
};
</script>
<style scoped lang="scss"></style>
